/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/core';
import Image from 'gatsby-image';

import { rhythm } from '../utils/typography';

const Bio = () => {
    const data = useStaticQuery(graphql`
        query BioQuery {
            avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
                childImageSharp {
                    fixed(width: 50, height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            site {
                siteMetadata {
                    author {
                        name
                        summary
                    }
                }
            }
        }
    `);

    const { author } = data.site.siteMetadata;
    return (
        <div
            css={css({
                display: `flex`,
                marginBottom: rhythm(2),
            })}
        >
            <Image
                fixed={data.avatar.childImageSharp.fixed}
                alt={author.name}
                css={css(imageStyle)}
                imgStyle={{
                    borderRadius: `50%`,
                }}
            />
            <p>
                Created by{' '}
                <a
                    href="https://www.facebook.com/profile.php?id=1078542454"
                    rel="noopener noreferrer"
                    target="_blank"
                    css={css(authorLinkStyle)}
                >
                    <strong>{author.name}</strong> {author.summary}
                </a>
            </p>
        </div>
    );
};

export default Bio;

const imageStyle = {
    marginRight: rhythm(1 / 2),
    marginBottom: 0,
    minWidth: 50,
    borderRadius: '100%',
};

const authorLinkStyle = {
    boxShadow: 'none',
    color: 'inherit',
};
